import React, { useState, useEffect } from 'react'
import {
  Flex,
  Box,
  Container,
  Heading,
  Paragraph,
  Text,
  Link,
} from 'theme-ui'
import { Helmet } from 'react-helmet'

import VideoPlayer from '../components/VideoPlayer'

const Success = ({ location, ...props }) => {
  const [lead, setLead] = useState()
  const query = (location.search || '').replace(/^\?/, '').split('&').reduce((acc, next) => {
    const [key, val] = next.split('=')

    return {
      ...acc,
      [key]: val
    }
  }, {})

  useEffect(() => {
    if (query.id) {
      fetch('/.netlify/functions/get-lead', {
        method: 'post',
        body: JSON.stringify({ id: query.id }),
      }).then(res => res.json())
        .then(json => {
          console.debug('Lead', json)
          setLead(json)
        })
    }
  }, [query.id])

  return <Box variant='boxes.success'>
    <Helmet title='2021 OPTECH Conference & Expo' />
    <Container sx={{ maxWidth: ['100%', '425px'], flex: '1 1 100%' }}>
      {lead && lead.response ? <VideoPlayer url={lead.response.url} /> : null}
      {lead && lead.user
        ?
          <Box p={4}>
            <Heading as='h1'>Hi {lead.user.name}!</Heading>
            <Paragraph>
              Here is an example of a prospect landing page with a personalized marketing video to create an unforgettable experience.
            </Paragraph>
            <Paragraph>
              The video and call-to-actions would change on this page as the customer moves deeper into the leasing funnel.
            </Paragraph>
            <Paragraph>
              Schedule a meeting to discuss how we can help you use this new technology to enhance the customer journey.
            </Paragraph>
          </Box>
        : null}
        {lead ? <Box p={[4, 0]}><Link href='https://meetings.hubspot.com/philip20/tradeshow-demo' variant='links.button'>Schedule Demo</Link></Box> : null}
    </Container>
    <Box as='footer' variant='boxes.successFooter'>
      <Container sx={{ maxWidth: ['100%', '425px'] }}>
        <Flex p={2} sx={{ justifyContent: 'center' }}>
          <Link variant='links.demo'>
            <Text color='white'>Apartment</Text>
            <Text ml={1}>Stories</Text>
          </Link>
        </Flex>
        <Flex p={2} sx={{ justifyContent: 'space-evenly' }}>
          <Link variant='links.demo'>Privacy Policy</Link>
          <Link variant='links.demo'>Terms of Use</Link>
        </Flex>
      </Container>
    </Box>
  </Box>
}

export default Success
